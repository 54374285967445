var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('h1',[_vm._v(" Valoración de "),_c('span',{staticClass:"font-weight-light"},[_vm._v("Motociclistas")])])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"label":"Filtrar por fecha","prepend-icon":"fa-calendar-alt","readonly":"","filled":"","rounded":"","hide-details":"","disabled":_vm.loading},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"range":"","title-date-format":_vm.title,"scrollable":"","max":_vm.today},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-row',{staticStyle:{"flex-wrap":"nowrap"},attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"large":"","rounded":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"large":"","rounded":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.date);
                  _vm.updateDateFilter();}}},[_vm._v(" Filtrar ")])],1)],1)],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"rgba(0, 0, 0, 0.6)","fab":"","dark":"","elevation":0,"minHeight":56,"loading":_vm.loading},on:{"click":_vm.toExcel}},on),[_c('v-icon',{attrs:{"x-large":"","dark":""}},[_vm._v("fa-file-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar .CSV")])])],1)],1),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.drivers,"items-per-page":8,"loading":_vm.loading,"sort-by":"totalRatings","search":_vm.$store.state.search,"sort-desc":true,"item-key":".key","fixed-header":"","single-expand":true},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-5",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","z-index":"999","width":"100%","height":"100%","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}}),_c('p',{class:_vm.$vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true},{key:`item.phone`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({staticStyle:{"display":"inline-block","text-transform":"capitalize"},attrs:{"color":_vm.$vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)',"dark":"","text-color":_vm.$vuetify.theme.dark ? 'white' : 'black'},on:{"click":function($event){return _vm.copyToClipboard(item.phone)}}},on),[_vm._v(" "+_vm._s(item.phone)+" ")])]}}],null,true)},[_c('i',{staticClass:"far fa-copy mr-2"}),_c('span',[_vm._v("Click para copiar")])])]}},{key:`item.email`,fn:function({ item }){return [(item.email)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({staticStyle:{"display":"inline-block","text-transform":"capitalize"},attrs:{"color":_vm.$vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)',"dark":"","text-color":_vm.$vuetify.theme.dark ? 'white' : 'black'},on:{"click":function($event){return _vm.copyToClipboard(item.email)}}},on),[_vm._v(" "+_vm._s(item.email)+" ")])]}}],null,true)},[_c('i',{staticClass:"far fa-copy mr-2"}),_c('span',[_vm._v("Click para copiar")])]):_vm._e()]}},{key:`item.fleet_thumb_image`,fn:function({ item }){return [_c('v-avatar',{staticClass:"mt-4"},[_c('img',{attrs:{"src":item.fleet_thumb_image,"alt":item.name}})])]}},{key:`item.goodPercentageRating`,fn:function({ item }){return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(" "+_vm._s(item.goodPercentageRating > 0 ? item.goodPercentageRating : 0)+" % ")])]}},{key:`item.negativeRatings`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.totalRatings - item.goodRatings)+" ")]}},{key:`item.options`,fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.viewRatings(item)}}},[_vm._v(" Ver valoraciones ")])]}}],null,true)})],1)],1)],1),(_vm.selectedUser)?_c('v-dialog',{attrs:{"max-width":"90%"},model:{value:(_vm.modalRatingsDetails),callback:function ($$v) {_vm.modalRatingsDetails=$$v},expression:"modalRatingsDetails"}},[_c('ratings-details',{key:_vm.selectedUser.fleet_id,attrs:{"ratings":_vm.ratings,"user":_vm.selectedUser},on:{"cancel":function($event){_vm.modalRatingsDetails = false}}})],1):_vm._e(),_c('v-snackbar',{attrs:{"absolute":"","top":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }