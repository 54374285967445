<template>
  <div>
    <v-container fluid class="fill-height">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="4" md="4">
          <h1>
            Valoración de
            <span class="font-weight-light">Motociclistas</span>
          </h1>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date"
                label="Filtrar por fecha"
                prepend-icon="fa-calendar-alt"
                readonly
                filled
                rounded
                hide-details
                :disabled="loading"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              range
              :title-date-format="title"
              scrollable
              :max="today"
            >
              <v-row no-gutters style="flex-wrap: nowrap">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  outlined
                  color="primary"
                  @click="modal = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  color="primary"
                  @click="
                    $refs.dialog.save(date);
                    updateDateFilter();
                  "
                >
                  Filtrar
                </v-btn>
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="12" sm="2" md="2" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="rgba(0, 0, 0, 0.6)"
                fab
                v-on="on"
                class="mr-1"
                dark
                :elevation="0"
                :minHeight="56"
                :loading="loading"
                @click="toExcel"
              >
                <v-icon x-large dark>fa-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Exportar .CSV</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="drivers"
            :items-per-page="8"
            :loading="loading"
            sort-by="totalRatings"
            :search="$store.state.search"
            :sort-desc="true"
            item-key=".key"
            fixed-header
            :single-expand="true"
          >
            <template v-slot:loading>
              <div class="ma-5" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                  />
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                ></v-skeleton-loader>
              </div>
            </template>

            <template v-slot:[`item.phone`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block; text-transform: capitalize"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.phone)"
                  >
                    {{ item.phone }}
                  </v-chip>
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.email`]="{ item }">
              <v-tooltip v-if="item.email" right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block; text-transform: capitalize"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.email)"
                  >
                    {{ item.email }}
                  </v-chip>
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.fleet_thumb_image`]="{ item }">
              <v-avatar class="mt-4">
                <img :src="item.fleet_thumb_image" :alt="item.name" />
              </v-avatar>
            </template>

            <template v-slot:[`item.goodPercentageRating`]="{ item }">
              <v-chip class="ma-2" color="green" text-color="white">
                {{
                  item.goodPercentageRating > 0 ? item.goodPercentageRating : 0
                }}
                %
              </v-chip>
            </template>

            <template v-slot:[`item.negativeRatings`]="{ item }">
              {{ item.totalRatings - item.goodRatings }}
            </template>

            <template v-slot:[`item.options`]="{ item }">
              <v-btn @click="viewRatings(item)" small color="primary">
                Ver valoraciones
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-if="selectedUser" v-model="modalRatingsDetails" max-width="90%">
      <ratings-details
        :ratings="ratings"
        @cancel="modalRatingsDetails = false"
        :key="selectedUser.fleet_id"
        :user="selectedUser"
      ></ratings-details>
    </v-dialog>

    <v-snackbar absolute top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { Parser } from "json2csv";
import { utils, write } from 'xlsx';
// import axios from "axios";
import ratingsDetails from "./ratings-details";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "bikers-rating",
  components: {
    lottie: Lottie,
    ratingsDetails,
  },
  mixins: [lottieSettings],
  data() {
    return {
      modal: null,
      date: [
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
      ],
      snackbar: false,
      drivers: [],
      ratings: [],
      snackbarText: null,
      loading: true,
      currentTime: Date.now(),
      modalRatingsDetails: false,
      selectedUser: null,
      headers: [
        {
          value: "fleet_thumb_image",
        },
        {
          text: "Driver Id",
          value: "fleet_id",
        },
        {
          text: "Nombre",
          value: "name",
          width: "250px",
        },
        {
          text: "Telefono",
          value: "phone",
        },
        {
          text: "Correo",
          value: "email",
        },
        {
          text: "Total de valoraciones",
          value: "totalRatings",
        },
        {
          text: "Valoraciones positivas",
          value: "goodRatings",
        },
        {
          text: "Valoraciones negativas",
          value: "negativeRatings",
        },
        {
          text: "Porcentaje de valoraciones positiva",
          value: "goodPercentageRating",
        },
        {
          value: "options",
          align: "end",
        },
      ],
    };
  },
  computed: {
    search() {
      return this.$store.state.search;
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  methods: {
    title() {
      return "Selecciona las fechas a filtrar";
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    toExcel() {
      const filename = `drivers-ratings-${this.date[0]}-${this.date[1]}.xlsx`;

      const fields = [
        { header: "Driver Id", key: "fleet_id" },
        { header: "Nombre del conductor", key: "name" },
        { header: "Correo", key: "email" },
        { header: "Teléfono", key: "phone" },
        { header: "Total de valoraciones", key: "totalRatings" },
        { header: "Valoraciones positivas", key: "goodRatings" },
        { header: "Valoraciones negativas", key: "negativeRatings" },
        { header: "Porcentaje de valoraciones positivas", key: "goodPercentageRating" }
      ];

      const data = this.drivers.map(driver => ({
        ...driver,
        negativeRatings: driver.totalRatings - driver.goodRatings,
        goodPercentageRating: driver.goodPercentageRating > 0 ? driver.goodPercentageRating : 0
      }));

      // Crear una nueva hoja de cálculo
      const ws = utils.json_to_sheet(data, { header: fields.map(f => f.key) });

      // Agregar encabezados
      utils.sheet_add_aoa(ws, [fields.map(f => f.header)], { origin: 'A1' });

      // Crear un nuevo libro de trabajo y agregar la hoja
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Drivers Ratings");

      // Generar el archivo binario
      const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });

      // Crear un Blob con el contenido del archivo
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Descargar el archivo
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    },
    updateDateFilter() {
      if (this.date.length > 1) {
        if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      } else {
        this.date = [this.date[0], this.date[0]];
      }

      this.getDriversRatings();
    },

    async getDriversRatings() {
      this.loading = true;
      this.drivers = [];

      let query = db
        .collection(`ratings`)
        .where(
          "answeredAt",
          ">",
          moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
        )
        .where(
          "answeredAt",
          "<",
          moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
        );

      await this.$binding("ratings", query);

      this.ratings = this.ratings.filter((e) => e.answered);

      // var httpTookanGetDrivers = fb
      //   .functions()
      //   .httpsCallable("httpTookanGetDrivers");
      // httpTookanGetDrivers({})
      db.collection("drivers")
        .get()
        .then((result) => {
          this.drivers = result.docs;

          this.drivers = this.drivers.map((driver) => {
            let driverData = driver.data();

            let ratingsByDriver = this.ratings.filter((rating) => {
              return rating.driverId == driverData.fleet_id;
            });
            let goodFeedback = ratingsByDriver.filter(
              (rating) => rating.isDriverRatedGood
            );
            driverData.goodPercentageRating = parseInt(
              (goodFeedback.length / ratingsByDriver.length) * 100
            );
            driverData.totalRatings = ratingsByDriver.length;
            driverData.goodRatings = goodFeedback.length;
            return driverData;
          });

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.snackbar = true;
          this.snackbarText = "Imposible obtener lista de chavos disponibles.";
        });

      // this.loading = false;
    },
    viewRatings(item) {
      this.selectedUser = item;
      this.modalRatingsDetails = true;
    },
  },
  destroyed() {
    this.$store.commit("setSearchTerm", "");
    this.$store.commit("setSearch", "");
  },
  async mounted() {
    this.loading = true;
    this.$store.commit("setSearchTerm", "motociclista");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;
    this.getDriversRatings();
  },
};
</script>
